<template>
  <div class="admin">
    <h1>Admin</h1>
    <form @submit.prevent="login">
      <input v-model="password" type="password" placeholder="Mot de passe" />
      <button type="submit">Se connecter</button>
    </form>
    <div v-if="isLoggedIn">
      <h2>Modifier les sujets</h2>
      <!-- Ajoutez ici des fonctionnalités pour modifier le fichier JSON -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: '',
      isLoggedIn: false,
      correctPassword: 'votre_mot_de_passe'  // Remplacez par un mot de passe sécurisé
    };
  },
  methods: {
    login() {
      if (this.password === this.correctPassword) {
        this.isLoggedIn = true;
      } else {
        alert('Mot de passe incorrect');
      }
    }
  }
};
</script>

<style scoped>
.admin {
  padding: 16px;
}
</style>
