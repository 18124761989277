import { createRouter, createWebHistory } from 'vue-router';  // Utilisez les nouvelles fonctions de vue-router
import HomePage from '@/components/HomePage.vue';
import SujetPage from '@/components/SujetPage.vue';
import AdminPage from '@/components/AdminPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/sujets/:titre',
    name: 'Sujet',
    component: SujetPage,
    props: true
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminPage,
    meta: { requiresAuth: true }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
