<template>
    <div class="home">
      <h1>Sujets</h1>
      <div class="button-container">
        <button v-for="(sujet, index) in sujets" :key="index" @click="goToSujet(sujet.titre)" class="sujet-button">
          {{ sujet.titre }}
        </button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        sujets: []
      };
    },
    async created() {
      const response = await fetch('/sujets.json');
      this.sujets = await response.json();
    },
    methods: {
      goToSujet(titre) {
        this.$router.push(`/sujets/${encodeURIComponent(titre)}`);
      }
    }
  };
  </script>
  
  <style scoped>
  .home {
    text-align: center;
    padding: 16px;
  }
  
  .button-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
  }
  
  .sujet-button {
    padding: 20px 40px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.5em;
    transition: background-color 0.3s;
  }
  
  .sujet-button:hover {
    background-color: #0056b3;
  }
  </style>
  