<template>
  <div class="sujet">
    <button @click="goBack" class="back-button">
      <i class="fas fa-arrow-left"></i> Retour
    </button>
    <h1>{{ sujet.titre }}</h1>
    <div class="description">
      <p>{{ sujet.description }}</p>
    </div>
    <div class="grid-container">
      <div
        v-for="(lien, index) in sujet.liens"
        :key="index"
        class="grid-item"
        @mouseover="addJelloEffect($event)"
        @mouseleave="removeJelloEffect($event)"
      >
        <a :href="lien.url" target="_blank" class="link-button">
          <i class="fas fa-link"></i> {{ lien.nom }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sujet: {}
    };
  },
  async created() {
    const titreSujet = decodeURIComponent(this.$route.params.titre);
    const response = await fetch('/sujets.json');
    const sujets = await response.json();
    this.sujet = sujets.find(sujet => sujet.titre === titreSujet) || {};
  },
  methods: {
    goBack() {
      this.$router.push('/');
    },
    addJelloEffect(event) {
      const element = event.currentTarget;
      element.classList.add('animate__jello');
    },
    removeJelloEffect(event) {
      const element = event.currentTarget;
      element.classList.remove('animate__jello');
    }
  }
};
</script>

<style scoped>
.sujet {
  padding: 16px;
  text-align: center;
}

.back-button {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1em;
  margin-bottom: 20px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-button i {
  margin-right: 8px;
}

.back-button:hover {
  background-color: #5a6268;
}

h1 {
  margin-bottom: 10px;
}

.description {
  margin: 0 auto;
  max-width: 800px; /* Ajustez selon vos besoins */
  padding: 10px;
  background: #ffffff;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
  justify-items: center;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #057AFF; /* Couleur de fond bleue uniforme */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 200px;
  height: 200px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.grid-item:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.link-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  background: none;
  color: #ffffff; /* Couleur du texte en blanc pour contraster avec le bleu */
  text-decoration: none;
  border-radius: 8px;
  font-size: 1.2em;
  transition: color 0.3s;
}

.link-button i {
  font-size: 2em;
  margin-bottom: 10px;
}

.link-button:hover {
  color: #f0f0f0; /* Couleur du texte au survol */
}

/* Animation jello ajoutée sur hover */
.animate__jello {
  animation: jello 0.9s;
}

@keyframes jello {
  0% { transform: scale(1); }
  50% { transform: scale(1.1) rotate(3deg); }
  100% { transform: scale(1) rotate(0deg); }
}
</style>

